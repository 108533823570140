import React from "react";
import { useMediaQuery } from "@mui/material";

export const BaseColor = {
  whiteSmoky: {
    color: 'whitesmoke',
  },
  Headcolor: {
    fontSize: "30",
    fontFamily: "",


  },
};

